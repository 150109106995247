/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { useTenantId } from '@samc/single-spa-client-configuration';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserHistory, HashHistory } from 'history';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import { LayerWrapper, toastError, ToastMessage, ToastMessageContainer } from '@samc/react-ui-core';
import { HistoryProvider } from '@samc/react-ui-history';
import { ApiProvider } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { useUserAuthenticationState } from '@samc/single-spa-authentication';
import { Router } from './templates/Router/Router';

export interface SingleSpaProps {
    styletronEngine: Styletron;
    browserHistory: BrowserHistory;
    hashHistory: HashHistory;
}

const queryClient = new QueryClient();

const Wrapper = (props: SingleSpaProps): React.ReactElement => {
    const { styletronEngine, browserHistory, hashHistory } = props;

    const authState = useUserAuthenticationState();
    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState<boolean>(false);

    const tenantName = useTenantId();

    React.useEffect(() => {
        const isAuthenticated = !!(authState && authState.isAuthenticated);
        setIsUserAuthenticated(isAuthenticated);
    }, [authState]);

    if (!isUserAuthenticated) return <></>;

    const headers = {
        Authorization: `Bearer ${authState?.accessToken?.value}`,
    };

    return (
        <StyletronProvider value={styletronEngine} debugAfterHydration>
            <LayerWrapper>
                <HistoryProvider value={{ browserHistory, hashHistory }}>
                    <DndProvider backend={HTML5Backend}>
                        <CustomRouter location={location} navigator={hashHistory}>
                            <QueryClientProvider client={queryClient}>
                                <ApiProvider
                                    value={{
                                        requestInit: {
                                            headers,
                                        },
                                        tenantName,
                                        UserLanding: '/_apis/user-landing/',
                                        StrataApi: '/_apis/user-landing/strata',
                                        ShowMessage: (msg: string): React.ReactText =>
                                            toastError(<ToastMessage title="Error" message={msg} />),
                                    }}
                                >
                                    <Router />
                                    <ToastMessageContainer
                                        className="single-spa-user-landing"
                                        containerId="landingpage"
                                        position="top-center"
                                        draggable
                                        pauseOnHover
                                        pauseOnFocusLoss
                                        autoClose={4000}
                                        icon={false}
                                    />
                                </ApiProvider>
                            </QueryClientProvider>
                        </CustomRouter>
                    </DndProvider>
                </HistoryProvider>
            </LayerWrapper>
        </StyletronProvider>
    );
};

export default Wrapper;
