import * as React from 'react';
import { RequestResult, RequestError, useRequestBase } from '@samc/react-ui-request';
import { ApiContextType, ApiContext } from '../Apicontext';
import { getCardList } from '../Request';

export const LANDING_PAGE_KEY = 'landing_page_card';

export const useCardList = (): RequestResult<Record<string, unknown>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const query = async (url?: string): Promise<Record<string, unknown>> => {
        return getCardList(url, api.requestInit);
    };

    return useRequestBase<Record<string, unknown>, RequestError, ApiContextType, Parameters<typeof getCardList>>({
        key: LANDING_PAGE_KEY,
        query,
        requestContext: ApiContext,
        params: [api.UserLanding],
    });
};
