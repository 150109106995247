import * as React from 'react';
import { Route, Routes } from 'react-router';
import LandingPage from '../../pages/LandingPage/LandingPage';

export const Router = (): React.ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
        </Routes>
    );
};

export default Router;
