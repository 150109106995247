import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const lifecycles = singleSpaReact({
    renderType: 'createRoot',
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        return <></>;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
