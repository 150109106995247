/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { CardTile, ClassNameBuilder } from '@samc/react-ui-core';
import { Icon } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { ApiContextType, ApiContext } from '@samc/screen-config-api';
import { useImagesFromStrataIds } from '@samc/screen-config-docs';
import { useHistoryContext } from '@samc/react-ui-history';
import { TenantManager, useTenantId } from '@samc/single-spa-client-configuration';
import { CardList, PortfolioList } from '../../constants/interfaces';
import { useContextToken } from '../../hooks/useContextToken/useContextToken';
import TaskCountTitle from '../TaskCountTitle/TaskCountTitle';

interface CardProps {
    client: CardList;
}

const Card = (props: CardProps): React.ReactElement => {
    const { client } = props;

    const currentTenantId = useTenantId();
    const [selectedTenantId, setSelectedTenantId] = React.useState<string>();
    const [portfolioSelected, setPortfolioSelected] = React.useState<PortfolioList | undefined>(undefined);
    const [logo, setLogo] = React.useState<string | undefined>(undefined);
    const [redirectsToOverdueTask, setRedirectsToOverdueTask] = React.useState<boolean>(false);
    const [isTaskCount, setIsTaskCount] = React.useState<boolean>(false);

    const [css] = useStyletron();
    const { token } = useContextToken();
    const { browserHistory } = useHistoryContext();
    const api = React.useContext<ApiContextType>(ApiContext);
    const { imagesSources } = useImagesFromStrataIds(
        [client.clientImage as string],
        token,
        'global',
        api?.StrataApi || '',
    );

    const onClientSelected = (_client: CardList): void => {
        setSelectedTenantId(_client.shortName);
        TenantManager.setTenantId(_client.shortName, { suppressReload: true });
    };

    const onRedirect = (clientSelected: CardList, itemSelected: PortfolioList): void => {
        onClientSelected(clientSelected);
        setPortfolioSelected(itemSelected);
    };

    const currentTenantSelected = currentTenantId && selectedTenantId && currentTenantId === selectedTenantId;
    React.useEffect(() => {
        if (currentTenantSelected) {
            if (redirectsToOverdueTask && portfolioSelected) {
                const { id } = portfolioSelected;
                browserHistory.push(
                    `/valuations/#/screens/VALU_ValuationWorkflowTask_ValuationTasks/3b11vljlm?PortfolioId=${id}`,
                );
            } else if (isTaskCount && portfolioSelected) {
                const { id } = portfolioSelected;
                browserHistory.push(
                    `/valuations/#/screens/VALU_ValuationWorkflowTask_ValuationTasks?PortfolioId=${id}`,
                );
            } else if (portfolioSelected) {
                const { id } = portfolioSelected;
                browserHistory.push(`/valuations/#/screens/AllValuation?PortfolioId=${id}`);
            } else {
                browserHistory.push(`/valuations/#/screens/AllValuation`);
            }
        }
    }, [currentTenantSelected, portfolioSelected, browserHistory, isTaskCount]);

    React.useEffect(() => {
        if (!client.clientImage) {
            setLogo('N/A');
        }
        if (imagesSources.length) {
            setLogo(imagesSources[0]);
        }
    }, [client.clientImage, imagesSources]);

    return (
        <CardTile
            className={css({ margin: '10px' })}
            key={client.id}
            name={client.longName}
            logo={logo}
            onClick={(): void => onClientSelected(client)}
        >
            <div className={css({ minHeight: '250px' })}>
                {client.portfolios.length > 0 && <TaskCountTitle />}
                <div
                    className={css({
                        marginTop: '10px',
                        display: 'block',
                        flexDirection: 'column',
                        padding: '0px 5px',
                        maxHeight: '180px',
                        overflow: 'auto',
                    })}
                >
                    {client.portfolios.map((item) => {
                        const { portfolioAccesses } = item;
                        return (
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '30px',
                                    alignItems: 'center',
                                    borderTop: '1px solid rgba(128, 128, 128, 0.397)',
                                })}
                                key={client.id}
                            >
                                <div
                                    data-testid="itemTask"
                                    className={ClassNameBuilder(
                                        'itemTask',
                                        css({ color: '#214D88', width: '50%', cursor: 'pointer' }),
                                    )}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {
                                        onRedirect(client, item);
                                    }}
                                    onClick={() => {
                                        onRedirect(client, item);
                                    }}
                                >
                                    {item.name}
                                </div>
                                {portfolioAccesses && (
                                    <div
                                        data-testid="overdueTaskCount"
                                        className={ClassNameBuilder(
                                            'overdueTaskCount',
                                            css({
                                                color: 'rgb(189,14,8)',
                                                width: '25%',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }),
                                        )}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {
                                            onRedirect(client, item);
                                        }}
                                        onClick={() => {
                                            onRedirect(client, item);
                                            setRedirectsToOverdueTask(true);
                                        }}
                                    >
                                        {portfolioAccesses[0].overdueTaskCount?.toString() || ''}
                                    </div>
                                )}
                                {portfolioAccesses && (
                                    <div
                                        data-testid="upcomingTaskCount"
                                        className={ClassNameBuilder(
                                            'upcomingTaskCount',
                                            css({
                                                color: 'rgb(19,40,145)',
                                                width: '25%',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }),
                                        )}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {
                                            onRedirect(client, item);
                                        }}
                                        onClick={() => {
                                            onRedirect(client, item);
                                            setIsTaskCount(true);
                                        }}
                                    >
                                        {portfolioAccesses[0].upcomingTaskCount?.toString() || ''}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <Icon
                className={css({
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'end',
                    cursor: 'pointer',
                })}
                iconName="Forward"
                onClick={(): void => onClientSelected(client)}
            />
        </CardTile>
    );
};
export default Card;
