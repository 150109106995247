import * as React from 'react';
import { TenantManager, useTenantId } from '@samc/single-spa-client-configuration';

export const useMissingTenantSetter = (fallbackTenantId: string | undefined): void => {
    const tenant = useTenantId();

    React.useEffect(() => {
        // Tenant is already set, no further action needed
        if (tenant) {
            return;
        }

        // We don't have anything to set the missing tenant to
        if (!fallbackTenantId) {
            return;
        }

        TenantManager.setTenantId(fallbackTenantId, { suppressReload: false });
    }, [fallbackTenantId, tenant]);
};

export default useMissingTenantSetter;
