import React from 'react';
import { useStyletron } from 'styletron-react';

const TaskCountTitle = (): React.ReactElement => {
    const [css] = useStyletron();
    return (
        <>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    fontWeight: 'bold ',
                    marginTop: '10px',
                    padding: '2px',
                })}
            >
                <div
                    className={css({
                        width: '50%',
                        borderBottom: '1px solid rgba(128, 128, 128, 0.397)',
                        textAlign: 'center',
                    })}
                >
                    My Valuation Task
                </div>
            </div>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    textAlign: 'center',
                })}
            >
                <div className={css({ width: '25%', fontWeight: 'bold', textAlign: 'center' })}>Overdue</div>
                <div className={css({ width: '25%', fontWeight: 'bold', textAlign: 'center' })}>Upcoming</div>
            </div>
        </>
    );
};

export default TaskCountTitle;
