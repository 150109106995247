import * as GenericApiContext from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';

export const REQUIREDURIS = ['UserLanding'];

export interface ApiContextType extends GenericApiContext.ApiContextType {
    UserLanding: string;
}

export const ApiContext = GenericApiContext.ApiContext as unknown as React.Context<ApiContextType>;
export const ApiProvider = GenericApiContext.ApiProvider as unknown as React.Provider<ApiContextType>;
