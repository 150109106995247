import React from 'react';
import { ApiContext, ApiContextType } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';

/* istanbul ignore next */
export const useContextToken = (): { token: string } => {
    const [token, setToken] = React.useState<string>('');
    const headers: HeadersInit | undefined = React.useContext<ApiContextType>(ApiContext).requestInit?.headers;

    React.useEffect(() => {
        if (headers) {
            const authHeader = Object.entries(headers)
                .filter(([key]) => key === 'Authorization')
                .pop();
            const tokenTmp: string = authHeader?.pop().split(' ').pop() || '';
            setToken(tokenTmp);
        }
    }, [headers]);

    return { token };
};

export default useContextToken;
