enum SpinnerSize {
    /**
     * 12px Spinner diameter
     */
    xSmall = 0,
    /**
     * 16px Spinner diameter
     */
    small = 1,
    /**
     * 20px Spinner diameter
     */
    medium = 2,
    /**
     * 28px Spinner diameter
     */
    large = 3,
}

export default SpinnerSize;
