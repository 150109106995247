import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ClassNameBuilder, BrandHeader, toastError, ToastMessage } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { useCardList } from '../../api/useCardList/useCardList';
import { CardList } from '../../constants/interfaces';
import Card from '../../components/card/Card';
import background from '../../assets/situsbg.png';
import titleLogo from '../../assets/VMSNext.svg';
import SpinnerSize from '../../constants/constants';

interface LandingPageProps {
    className?: string;
}

const LandingPage = (props: LandingPageProps): React.ReactElement => {
    const { className } = props;

    const clients = useCardList();
    const [css] = useStyletron();

    if (clients.isLoading) {
        return (
            <div className={css({ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' })}>
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }

    // Error popup when User Landing API call fails
    if (clients.error && !clients.data) {
        toastError(<ToastMessage title="User Landing Error" message="Something went wrong while retrieving data." />, {
            containerId: 'landingpage',
        });
    }

    // Error popup when User Management API returns an error
    if (
        !clients.error &&
        clients.data &&
        clients.data.error &&
        clients.data.messages &&
        Array.isArray(clients.data.messages)
    ) {
        const { error, messages } = clients.data;
        const errorTitle = error as string;
        const errorDescription = (messages[0]?.detail as string) || (messages[0]?.title as string);

        toastError(<ToastMessage title={errorTitle} message={errorDescription} />, {
            containerId: 'landingpage',
        });
    }

    // Error popup when the user does not have any tenants asigned
    if (clients.data && Array.isArray(clients.data) && !(clients.data as Array<unknown>).length) {
        toastError(
            <ToastMessage
                title="Missing Access"
                message="You have not been given access to any clients, please contact your system administrator."
            />,
            { containerId: 'landingpage' },
        );
    }

    return (
        <>
            <div className={css({ background: `url(${background})` })}>
                <BrandHeader title="Valuation Management System" logoPath={titleLogo} />
            </div>
            <div
                className={ClassNameBuilder(
                    css({
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        height: 'auto',
                        padding: '15px',
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                    }),
                    'samc-user-landing-page-container',
                    className,
                )}
            >
                {Array.isArray(clients.data) &&
                    !clients.data.error &&
                    clients.data.map((client: CardList) => {
                        return <Card key={client.id} client={client} />;
                    })}
            </div>
        </>
    );
};

export default LandingPage;
